
import { computed, defineComponent, reactive, ref, Ref, watch } from "vue";
import $API from "@/services";
import FileUploader from "@/components/common/FileUploader";
import Textarea from "primevue/textarea";
import { AxiosResponse } from "axios";
import {
  IAlert,
  IDoctorScheduled,
  IResponseData,
  ITimeList,
  RouterName,
  StatusTexts,
} from "@/models/common";
import { IDoctor, PossibleTypes } from "@/models/doctor";
import { useStore } from "@/store";
import { CommonMutationTypes } from "@/store/modules/common/mutations";
import {
  IRequest,
  RequestKindTypes,
  RequestStatuses,
  RequestTypes,
} from "@/models/request";
import { IFileListItem } from "@/models/file";
import FileList from "@/components/file/FileList.vue";
import { DataType, FileType } from "@/services/file";
import Doctors from "@/components/common/Doctors/Doctors";
import DateFormat from "@/utils/date-format";
import { IOnlineCarePurpose, OnlineCare } from "@/models/onlineCare";
import DoctorScheduled from "@/components/onlineCare/DoctorScheduled.vue";
import router from "@/router";
import { Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import PharmacyList from "@/components/common/PharmacyList.vue";
import { messageType } from "@/models/message";
import PageFrame from "@/components/common/PageFrame.vue";
import CustomConfirmDialog from "@/components/primevueCustom/ConfirmDialog.vue";
import { useConfirm } from "primevue/useconfirm";
import OverlayPanel from "primevue/overlaypanel";
import { getDoctor, getDoctorList } from "@/services/doctor";
import DoctorItem from "@/components/common/Doctors/DoctorItem.vue";
import Button from "primevue/button";
import moment from "moment";

const PageTypes = RequestTypes;
enum PageStates {
  "DOCTOR",
  "PURPOSE",
}

export default defineComponent({
  name: "Online Care Request Edit",
  props: {
    id: String,
  },
  setup(props) {
    const timeList: ITimeList = reactive({
      am: [
        "09:20",
        "09:40",
        "10:00",
        "10:20",
        "10:40",
        "11:00",
        "11:20",
        "11:40",
        "12:00",
        "12:20",
        "12:40",
      ],
      pm: ["14:20", "14:40", "15:00", "15:20", "15:40", "16:00"],
    });

    const doctorScheduled: IDoctorScheduled = reactive({
      DoctorScheduledDays: "",
      ReqScheduleds: [],
    });

    const doctors: Ref<IDoctor> = ref({} as IDoctor);

    const tempDoctor = reactive({
      doctorScheduled: {} as IDoctorScheduled,
      doctor: {} as IDoctor,
    });

    const op$: Ref<OverlayPanel | null> = ref(null);
    const doctors$: Ref<Doctors | null> = ref(null);
    const fileUploader$: Ref<FileUploader | null> = ref(null);

    const state = reactive({
      id: props.id,
      page: PageStates.PURPOSE,
      pageType: PageTypes.NORMAL,
      purpose: {
        date: "",
        time: "",
        doctorKey: null as Number | null,
        other: "",
        files: null as Array<IFileListItem> | null,
        pharmacyKey: null as Number | null,
        otherComment: "",
      } as IOnlineCarePurpose,
      requestStatus: null as RequestStatuses | null,
      selectFile: null as IFileListItem | null,
      visibleFull: false,
      isLoading: true,
      message: "",
    });

    const formValidate = reactive({
      time: false,
      date: false,
      doctorKey: false,
      other: false,
    });

    const store = useStore();

    const confirm = useConfirm();
    const isConfirmButton = () => {
      confirm.require({
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "확인",
        group: "confirm",
        message: "수정 되었습니다.",
        accept: () => {
          goBack();
        },
        reject: () => {
          goBack();
        },
      });
    };
    const descMore = ref("");
    const opToggle = (event: any, profile: string) => {
      event?.preventDefault();
      descMore.value = profile;
      op$.value?.toggle(event);
    };
    const goBack = () => {
      router.go(-1);
    };

    const alert: Ref<IAlert> = ref({
      type: messageType.ERROR,
      content: "",
    });

    watch(
      () => state.purpose,
      (n: IOnlineCarePurpose) => {
        formValidate.doctorKey = !!n.doctorKey;
        formValidate.date = !!n.date;
        formValidate.time = !!n.time;
        formValidate.other = !!n.other;
      },
      { deep: true }
    );

    const url = computed(() => {
      return `${$API.BaseURL}/api/ApiFileUploadV2/Upload?reftab=request&refkey=${state.id}`;
    });

    const filesCount = computed(() => {
      return state.purpose.files?.filter(
        (f) => f.PrescriptionFlag !== 1 && f.FileStatus === 0
      ).length;
    });

    const isEditable = computed(() => {
      return (
        !state.id ||
        ((state.requestStatus ?? 100) >= RequestStatuses.Preparing &&
          (state.requestStatus ?? 100) < RequestStatuses.Scheduled)
      );
    });

    const { t } = useI18n({ useScope: "global" });

    const totalPages = computed(() => Object.keys(PageStates).length / 2);

    const selectDate = computed(() =>
      moment(new Date(state.purpose.date.toString())).format("YYYY-MM-DD")
    );

    return {
      state,
      doctorScheduled,
      PageStates,
      timeList,
      store,
      DataType,
      url,
      isEditable,
      formValidate,
      FileType,
      RequestStatuses,
      t,
      alert,
      filesCount,
      PossibleTypes,
      PageTypes,
      RequestKindTypes,
      totalPages,
      doctors,
      isConfirmButton,
      goBack,
      opToggle,
      op$,
      doctors$,
      fileUploader$,
      descMore,
      tempDoctor,
      selectDate,
    };
  },
  async mounted() {
    await this.onSetData();

    //의사 선택
    this.selectDoctor(this.doctors);

    //선택된 의사로 변경
    this.changedDoctor(false);
  },
  methods: {
    updatePharmacyKey(key: number | null) {
      this.state.purpose.pharmacyKey = key ?? 0;
    },
    async saveConfirm() {
      this.$confirm.require({
        header: this.t("ReceptionSaveHeader"),
        acceptLabel: this.t("Yes"),
        rejectLabel: this.t("No"),
        group: "save",

        accept: async () => {
          //callback to execute when user confirms the action
          await this.onSave();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },

    async deletedFile() {
      await this.onSetData();
    },

    filesProgress() {
      if (!this.store.getters.getIsFileUpdate) {
        setTimeout(() => {
          this.routerReplace();
        }, 1000);
      }
    },
    async onSetData() {
      try {
        this.state.isLoading = true;

        this.store.commit(CommonMutationTypes.SET_IS_LODING, true);

        //edit
        if (!!this.state.id && Number.parseInt(this.state.id)) {
          await this.getRequest(this.state.id);
          await this.getFiles(this.state.id);
        }
        await this.getDoctorList();
        this.doctors.doctorList = (
          (await getDoctorList(PossibleTypes.ONLINE_CARE)) as IDoctor
        ).doctorList;

        this.doctors.doctorListSplit = [this.doctors.doctorList];
        this.doctors.selectedPage = 0;
        this.doctors.displayDoctorRow = 0;

        this.doctors.selectedDoctor = [
          await getDoctor(this.state.purpose.doctorKey?.toString() ?? "0"),
        ];
      } catch (e) {
        this.store.commit(CommonMutationTypes.FILE_UPDATE, false);
        console.error(e);
      } finally {
        this.store.commit(
          CommonMutationTypes.SET_IS_LODING,
          this.store.getters.getIsFileUpdate
        );
        this.state.isLoading = false;
      }
    },
    routerReplace() {
      const id = this.state.id ?? 0;
      if (id === 0) return;
      router.replace({
        name: RouterName.ONLINE_CARE_REQUEST_VIEW,
        params: { id, isSave: 1 },
      });
      this.onSetData();
    },

    saveValidate() {
      return (
        this.formValidate.time &&
        this.formValidate.date &&
        this.formValidate.doctorKey &&
        this.formValidate.other
      );
    },

    async onSave() {
      try {
        this.store.commit(CommonMutationTypes.SET_IS_LODING, true);
        if (!this.saveValidate()) {
          this.alert = {
            content: "validateError",
            type: messageType.ERROR,
          };

          this.store.commit(CommonMutationTypes.SET_IS_LODING, false);
          return;
        }
        const datetime = DateFormat.StringDateTime(
          this.state.purpose.date.toString(),
          this.state.purpose.time.toString()
        );

        const requestKey = !this.state.id ? 0 : Number.parseInt(this.state.id);

        const saveData = new OnlineCare({
          datetime,
          other: this.state.purpose.other,
          doctorkey: this.state.purpose.doctorKey?.valueOf() ?? 0,
          requestKey,
          pharmacyKey: this.state.purpose.pharmacyKey?.valueOf() ?? 0,
        });
        const res: AxiosResponse = await $API.RequestService.save(saveData);

        if (res?.statusText === StatusTexts.OK || res?.status === 200) {
          this.state.id = res.data.ResultData.RequestKey;
          this.state.requestStatus = res.data.ResultData.RequestStatus;
          if (this.fileUploader$ && this.fileUploader$.getFilesCount() > 0)
            await this.fileUploader$.upload(this.url);
          else {
            this.store.commit(CommonMutationTypes.SET_IS_LODING, false);
            this.isConfirmButton();
          }
        }
      } catch (e) {
        console.error(e);

        this.alert = {
          content: "저장 실패",
          type: messageType.ERROR,
        };
      } finally {
        this.store.commit(CommonMutationTypes.SET_IS_LODING, false);
      }
    },
    async selectDoctor(doctor: IDoctor) {
      this.tempDoctor.doctor = doctor;

      const res: AxiosResponse<IResponseData<IDoctorScheduled>> =
        await this.getDoctorScheduled(
          this.tempDoctor.doctor.selectedDoctor[0].DoctorKey
        );
      const {
        data: { ResultData },
      } = res;
      if (!Array.isArray(ResultData)) {
        this.tempDoctor.doctorScheduled.DoctorScheduledDays =
          ResultData.DoctorScheduledDays ?? "";
        this.tempDoctor.doctorScheduled.ReqScheduleds =
          ResultData.ReqScheduleds;
      }
    },

    async changedDoctor(isResetDateTime: boolean = true) {
      this.state.purpose.doctorKey =
        this.tempDoctor.doctor.selectedDoctor[0].DoctorKey;
      if (isResetDateTime) {
        this.state.purpose.date = "";
        this.state.purpose.time = "";
      }
      this.doctors = this.tempDoctor.doctor;
      this.doctorScheduled = this.tempDoctor.doctorScheduled;
    },

    async getDoctorScheduled(doctorKey: Number) {
      return await $API.DoctorService.getScheduled(
        doctorKey,
        RequestKindTypes.OnlineCare
      );
    },
    submit(e: Event) {
      e.preventDefault();
      //(this.$refs.fileUpload as any).clear();
    },
    async getRequest(id: string) {
      const res: AxiosResponse<IResponseData<IRequest>> =
        await $API.RequestService.getRequest(Number.parseInt(id));
      const {
        data: { ResultData },
      } = res;
      if (!Array.isArray(ResultData)) {
        this.state.purpose.other = ResultData.PurposeOthers.toString() ?? "";
        this.state.requestStatus = ResultData.RequestStatus ?? null;
        this.state.purpose.pharmacyKey = ResultData.PharmacyKey;
        this.state.purpose.otherComment = ResultData?.OtherComments;
        this.state.pageType = ResultData.RequestType ?? 0;

        const schedul = DateFormat.splitDatetime(
          (
            ResultData.ScheduledDateTime ?? ResultData.DesiredDateTime1
          ).toString()
        );
        this.state.purpose.date = schedul.date;
        this.state.purpose.time = schedul.time;

        await this.getRequestDoctor(ResultData);
        await this.getDoctorScheduled(ResultData.DesiredDrKey);
      }
    },
    async getFiles(id: string) {
      const ResultData = await $API.FileService.getFile(id);
      if (!Array.isArray(ResultData)) return;
      this.state.purpose.files = ResultData;
    },
    async getRequestDoctor(resultData: IRequest) {
      this.state.purpose.doctorKey = resultData.DesiredDrKey;
    },

    async getDoctorList() {
      await this.doctors$?.onSetData();
    },
  },

  components: {
    FileUploader,
    Textarea,
    FileList,
    Doctors,
    DoctorScheduled,
    Form,
    CustomConfirmDialog,
    PharmacyList,
    PageFrame,
    DoctorItem,
    OverlayPanel,
    Button,
  },
});
